/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React, { FC, PropsWithChildren, useContext } from "react";
import { Experience } from "@ninetailed/experience.js-react";
import { ExperienceMapper } from "@ninetailed/experience.js-utils-contentful";
import { LayoutGroup } from "framer-motion";
import { useRunOnVisibleOnce } from "src/hooks/useIntersectionObserver";
import { ContentModuleContext } from "src/lib/context/ContentModuleContext";
import {
  addHeapContentModuleViewed,
  getLocationFromName,
} from "src/lib/services/elevar/events";

import type {
  IAssemblyLink,
  ICarouselSection,
  ICenteredRichText,
  ICollectionAssembly,
  ICollectionList,
  ICollectionSection,
  IDualRichTextSection,
  IEmailSignupSection,
  IFaqSection,
  IFaqSectionGroup,
  IGrid,
  IGrid2Dot5,
  IHero2Dot5,
  IHeroSectionBasic,
  IHighlightsSection,
  IHospitalityForm,
  IImageSection,
  IInstagramSection,
  ILinkList,
  INtExperience,
  IPackageTrackingSection,
  IPrivacySection,
  IProductSchematics,
  IPromoBlockSection,
  IPullQuoteSection,
  IReadMoreSection,
  IRecommendedProductsSection,
  IReviewSection,
  ISearchResultsSection,
  ISectionGroup,
  IShareForm,
  IShopTheLookSection,
  ISpacerSection,
  ISplitTextSection,
  ITabsSection,
  ITextOverlayMedia,
  ITextSection2Dot5,
  IVideoSection,
} from "../../../../@types/generated/contentful";
import { AnalyticsProvider } from "../../hooks/useAnalytics";

import CmsEditor, { CmsEditorContext } from "./CmsEditor";
import * as Sections from "./sections";
import selectors from "./selectors";
import {
  CardType,
  ProductCardType,
  PromoteH1Type,
  RenderSectionsProps,
  SectionType,
  TextMediaType,
} from "./types";
import { filterOutSectionsWithNoFields, getSectionPosition } from "./util";

type Entry = {
  sys: { id: string };
  fields: { nt_experiences?: INtExperience[] | undefined };
};

interface BaseProps<C extends Entry> {
  component:
    | FC<{ content: C; index: number }>
    | FC<{
        content: C;
        RenderSectionsComponent: FC<RenderSectionsProps>;
        index: number;
      }>;
  content: C;
}

interface WithRSCProps<C extends Entry> extends BaseProps<C> {
  RenderSectionsComponent: FC<RenderSectionsProps>;
}

type SectionWithExperienceProps<C extends Entry> = React.PropsWithChildren<
  BaseProps<C>
> &
  Partial<WithRSCProps<C>>;

function SectionWithExperience<C extends Entry>({
  component,
  content,
  RenderSectionsComponent,
  index,
}: SectionWithExperienceProps<C> & { index: number }) {
  const experiences = content.fields.nt_experiences ?? [];
  const mappedExperiences = experiences
    .filter((experience) => ExperienceMapper.isExperienceEntry(experience))
    .map((experience) =>
      ExperienceMapper.mapCustomExperience(experience, (variant) => ({
        id: variant.sys.id,
        content: variant,
        ...(RenderSectionsComponent ? { RenderSectionsComponent } : {}),
      }))
    );

  const componentProps = RenderSectionsComponent
    ? { content, RenderSectionsComponent, index }
    : { content, index };

  return (
    <Experience
      id={content.sys.id}
      component={component}
      // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
      {...(componentProps as any)}
      experiences={mappedExperiences}
    />
  );
}

function ContentSection({
  content,
  id,
  RenderSectionsComponent,
  space,
  environment,
  disableSectionStyling = false,
  index,
}: {
  content: SectionType;
  id: SectionType["sys"]["contentType"]["sys"]["id"];
  RenderSectionsComponent: FC<RenderSectionsProps>;
  space: string;
  environment: string;
  disableSectionStyling?: boolean;
  index: number;
}) {
  const moduleContextValue = React.useMemo(
    () => ({
      moduleEntryName:
        (content.fields as unknown as { name?: string }).name ?? "",
      moduleEntryId: content.sys.id,
      moduleType: id,
      moduleTitle: (content.fields as unknown as { title?: string }).title,
      positionId: content.id,
    }),
    [id, content]
  );

  switch (id) {
    case "carouselSection":
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.CarouselSectionSaddle
            content={content as ICarouselSection}
          />
        </ContentModuleContext.Provider>
      );

    case "textimageSection": {
      const typedContent = content as TextMediaType & PromoteH1Type;
      return (
        <AnalyticsProvider
          object={{
            id: typedContent.sys.id,
            name: typedContent.fields.name,
            type: typedContent.sys.contentType.sys.id,
          }}
        >
          <Sections.TextImageSectionSaddle content={typedContent} />
        </AnalyticsProvider>
      );
    }

    case "centeredRichText": {
      const typedContent = content as ICenteredRichText & PromoteH1Type;
      return (
        <AnalyticsProvider
          object={{
            id: typedContent.sys.id,
            name: typedContent.fields.name,
            type: typedContent.sys.contentType.sys.id,
          }}
        >
          <Sections.CenteredRichTextSaddle content={typedContent} />
        </AnalyticsProvider>
      );
    }

    case "dualRichTextSection":
      return (
        <SectionWithExperience
          component={Sections.DualRichTextSectionSaddle}
          content={content as IDualRichTextSection}
          index={index}
        />
      );

    case "textOverlayMedia": {
      const typedContent = content as ITextOverlayMedia;
      return (
        <AnalyticsProvider
          object={{
            id: typedContent.sys.id,
            name: typedContent.fields.name,
            type: typedContent.sys.contentType.sys.id,
          }}
        >
          <Sections.TextOverlayMediaSaddle
            content={content as ITextOverlayMedia}
            isSection={!disableSectionStyling}
          />
        </AnalyticsProvider>
      );
    }

    case "videoSection":
      return (
        <Sections.VideoSectionSaddle
          content={content as IVideoSection}
          environment={environment}
          space={space}
        />
      );

    case "instagramSection":
      return (
        <Sections.InstagramSectionSaddle
          content={content as IInstagramSection}
        />
      );

    case "pullQuoteSection":
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.PullQuoteSectionSaddle
            content={content as IPullQuoteSection}
          />
        </ContentModuleContext.Provider>
      );

    case "emailSignupSection":
      return (
        <Sections.EmailSignupSectionSaddle
          content={content as IEmailSignupSection}
        />
      );

    case "recommendedProductsSection":
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.RecommendedProductsSectionSaddle
            content={content as IRecommendedProductsSection}
          />
        </ContentModuleContext.Provider>
      );

    case "reviewSection":
      return (
        <Sections.ReviewSectionSaddle content={content as IReviewSection} />
      );

    case "productSchematics":
      return (
        <Sections.ProductSchematicsSaddle
          content={content as IProductSchematics}
        />
      );

    case "tabsSection":
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.TabSectionsSaddle
            content={content as ITabsSection}
            RenderSectionComponent={RenderSectionsComponent}
          />
        </ContentModuleContext.Provider>
      );

    case "privacySection":
      return (
        <Sections.PrivacySectionSaddle
          content={content as IPrivacySection & PromoteH1Type}
        />
      );

    case "faqSectionGroup":
      return (
        <Sections.FaqSectionGroupSaddle
          content={content as IFaqSectionGroup}
          id={content.sys.id}
        />
      );

    case "faqSection":
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.FaqSectionSaddle
            content={content as IFaqSection}
            id={content.sys.id}
          />
        </ContentModuleContext.Provider>
      );

    case "imageSection":
      return <Sections.ImageSectionSaddle content={content as IImageSection} />;

    case "splitTextSection":
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.SplitTextSectionSaddle
            content={content as ISplitTextSection & PromoteH1Type}
          />
        </ContentModuleContext.Provider>
      );

    case "promoBlockSection":
      return (
        <Sections.PromoBlockSectionSaddle
          content={content as IPromoBlockSection}
        />
      );

    case "hospitalityForm":
      return (
        <Sections.HospitalityFormSaddle content={content as IHospitalityForm} />
      );

    case "shareForm":
      return (
        <Sections.ShareFormSectionSaddle content={content as IShareForm} />
      );

    case "linkList":
      return <Sections.LinkListSectionSaddle content={content as ILinkList} />;

    case "assemblyLink":
      return (
        <Sections.AssemblyLinkSectionSaddle
          content={content as IAssemblyLink}
        />
      );

    case "grid":
      return (
        <SectionWithExperience
          component={Sections.GridSaddleWrapper}
          content={content as IGrid}
          RenderSectionsComponent={RenderSectionsComponent}
          index={index}
        />
      );

    case "card": {
      const typedContent = content as CardType;
      return (
        <AnalyticsProvider
          object={{
            name: typedContent.fields.name,
            id: typedContent.sys.id,
            type: typedContent.sys.contentType.sys.id,
          }}
        >
          <Sections.CardV2Saddle content={content as CardType} />
        </AnalyticsProvider>
      );
    }

    case "heroSectionBasic": {
      return (
        <SectionWithExperience
          component={Sections.HeroSectionSaddle}
          content={content as IHeroSectionBasic}
          index={index}
        />
      );
    }
    case "spacerSection": {
      return (
        <Sections.SpacerSectionSaddle content={content as ISpacerSection} />
      );
    }
    case "packageTrackingSection": {
      return (
        <Sections.PackageTrackingSectionSaddle
          content={content as IPackageTrackingSection}
        />
      );
    }
    case "searchResultsSection": {
      return (
        <Sections.SearchResultsSectionSaddle
          content={content as ISearchResultsSection}
        />
      );
    }
    case "readMoreSection": {
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.ReadMoreSectionSaddle
            content={content as IReadMoreSection}
          />
        </ContentModuleContext.Provider>
      );
    }
    case "collectionList": {
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.CollectionListSaddle
            content={content as ICollectionList}
            index={index}
          />
        </ContentModuleContext.Provider>
      );
    }
    case "highlightsSection": {
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.HighlightsSectionSaddle
            content={content as IHighlightsSection}
          />
        </ContentModuleContext.Provider>
      );
    }
    case "sectionGroup": {
      return (
        <SectionWithExperience
          component={Sections.SectionGroupSaddle}
          content={content as ISectionGroup}
          RenderSectionsComponent={RenderSectionsComponent}
          index={index}
        />
      );
    }
    case "productVariant": {
      return (
        <Sections.ProductCardSaddle content={content as ProductCardType} />
      );
    }
    case "shopTheLookSection": {
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.ShopTheLookSectionSaddle
            content={content as IShopTheLookSection}
          />
        </ContentModuleContext.Provider>
      );
    }
    case "collectionSection": {
      return (
        <ContentModuleContext.Provider value={moduleContextValue}>
          <Sections.CollectionSectionSaddle
            content={content as ICollectionSection}
            index={index}
          />
        </ContentModuleContext.Provider>
      );
    }
    case "collectionAssembly": {
      return (
        <Sections.CollectionAssemblySaddle
          content={content as ICollectionAssembly}
          RenderSectionsComponent={RenderSectionsComponent}
        />
      );
    }
    case "textSection2Dot5": {
      return (
        <Sections.TextSection2Dot5Saddle
          content={content as ITextSection2Dot5}
        />
      );
    }
    // TODO: fix the name in contentful
    case "hero2Dot5": {
      return <Sections.Hero2Dot5Saddle content={content as IHero2Dot5} />;
    }
    case "grid2Dot5": {
      return (
        <Sections.Grid2Dot5SectionSaddle content={content as IGrid2Dot5} />
      );
    }
    default:
      return (
        <Sections.SectionError
          key={content.sys.id}
          title="Not Implemented"
          entryID={content.sys.id}
          environment={environment}
          spaceID={space}
        >
          No component exists for the {content.sys.contentType.sys.id}{" "}
          content-type.
        </Sections.SectionError>
      );
  }
}

function SectionWrapper({
  content,
  children,
  isNested,
}: PropsWithChildren<{
  content: SectionType;
  isNested?: boolean;
}>) {
  const {
    sys: {
      id,
      contentType: {
        sys: { id: type },
      },
    },
    fields,
    id: positionId,
  } = content;
  let name = "";
  let title = "";
  if ("name" in fields && fields.name) {
    name = fields.name;
  }

  if ("title" in fields && fields.title) {
    title = fields.title;
  } else if ("sectionTitle" in fields) {
    title = fields.sectionTitle;
  }

  const wrapperRef = useRunOnVisibleOnce(
    () => {
      addHeapContentModuleViewed({
        moduleEntryName: name || title,
        moduleEntryId: id,
        moduleType: type,
        modulePosition: getSectionPosition(positionId),
        moduleName: getLocationFromName(undefined, name),
        moduleTitle: title,
      });
    },
    { threshold: 0.8 },
    1000
  );

  return (
    <div
      // Don't fire the event for nested sections or spacer / sectionGroup
      ref={
        !isNested && !["spacerSection", "sectionGroup"].includes(type)
          ? wrapperRef
          : undefined
      }
      data-section-position-id={positionId}
    >
      {children}
    </div>
  );
}

/**
 * Renders section content received from CMS
 */
export function RenderSections({
  sections,
  disableSectionStyling,
  isNested,
}: RenderSectionsProps) {
  const { showEditorTools, environment, space } = useContext(CmsEditorContext);

  return (
    <LayoutGroup>
      {/* This is a proposed solution to add space between sections. This is easy to undo if we need to go in a different direction */}
      <div className="grid grid-cols-1" data-testid={selectors.container}>
        {sections
          .filter(filterOutSectionsWithNoFields)
          .map((content, index) => (
            <SectionWrapper
              key={content.sys.id}
              content={content}
              isNested={isNested}
            >
              <div
                content-type={content.sys.contentType.sys.id}
                render-section-index={!isNested ? index : undefined}
                entry-id={content.sys.id}
                data-event-category={!isNested ? "render-section" : undefined}
              >
                <ContentSection
                  content={content}
                  id={content.sys.contentType.sys.id}
                  index={index}
                  RenderSectionsComponent={RenderSections}
                  space={space}
                  environment={environment}
                  disableSectionStyling={disableSectionStyling}
                />
                {showEditorTools && (
                  <CmsEditor
                    environment={environment}
                    entryID={content.sys.id}
                    spaceID={space}
                  />
                )}
              </div>
            </SectionWrapper>
          ))}
      </div>
    </LayoutGroup>
  );
}
